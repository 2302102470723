<template>
  <div style="width: 100%; height: 100%">
    <v-skeleton-loader
      ref="skeleton"
      :type="type"
      class="mx-auto"
      v-for="n in occurrence"
      :key="`skeleton-${n}`">
    </v-skeleton-loader>
  </div>
</template>

<script>
  export default
  {
    name: "Skeleton",
    props: {
      type: {type: String, default: "list-item-avatar-three-line"},
      occurrence: {type: Number, default: 6}
    }
  }
</script>

<style scoped>

</style>